.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding: 0 1rem;

  @media (min-width: 960px) {
    padding: 0 4rem;

    &::after,
    &::before {
      background: #d4f2f9;
      content: "";
      display: block;
      position: absolute;
      z-index: -1;
    }

    &::after {
      bottom: 0;
      height: 50%;
      right: 0;
      width: 75%;
    }

    &::before {
      height: 17.5rem;
      left: 0;
      top: 0;
      width: 35rem;
    }
  }

  &-ad {
    display: none;

    @media (min-width: 960px) {
      display: block;
      flex-grow: 1;
    }

    > div {
      height: 100%;
      position: relative;
      width: 100%;

      > div {
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;

        > img {
          height: 100%;
          object-fit: cover;
          width: 100%;
        }
      }
    }
  }

  &-header {
    padding: 1.5rem;
    text-align: center;
  }

  &-main {
    background: #fff;
    border-radius: 0.375rem;
    box-shadow: 0 0.5rem 0.5rem rgba(#979797, 0.25);
    display: flex;
    flex-grow: 1;
    margin-bottom: 4.5rem;
  }

  &-routes {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    width: 100%;

    @media (min-width: 960px) {
      padding: 3rem;
      width: 36rem;
    }

    > a {
      align-items: center;
      background: #d41e33;
      border: 1px solid #d41e33;
      border-radius: 0.375rem;
      color: #fff;
      display: inline-flex;
      font-weight: bold;
      justify-content: center;
      padding: 0.5rem 1rem;
      position: relative;
      text-align: center;
      text-decoration: none;
      white-space: pre-line;

      &:focus,
      &:hover {
        background: #a2224c;
        border-color: #a2224c;
        color: #fff;
      }
    }

    > img {
      margin: auto auto 0 auto;
    }
  }

  &-stores {
    text-align: center;

    > ul {
      display: flex;
      flex-wrap: wrap;
      gap: 0.5rem;
      justify-content: center;
      list-style: none;
      padding: 0;

      > li {
        > a {
          &:focus,
          &:hover {
            opacity: 0.75;
          }
        }
      }
    }
  }
}
