@import "~normalize.scss";

#root {
  height: 100vh;
  min-height: 100vh;
}

body {
  background: #f9f9f9;
  color: #333333;
  font-family: Barlow, sans-serif;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.25;
}

a {
  color: currentColor;
  text-decoration: underline;

  &:focus,
  &:hover {
    text-decoration: none;
  }
}

img,
svg {
  flex-shrink: 0;
  vertical-align: middle;
}

p {
  margin: 0;
  padding: 0;
  white-space: pre-line;

  &:not(:last-child) {
    margin-bottom: 1.5rem;
  }
}
